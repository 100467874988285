import React, { ReactNode } from "react";
import classes from "./styles.module.scss";
export interface ButtonProps {
  id?: string;
  onClick?: (e: any) => void;
  size?: "small" | "medium" | "large" | "savebtn";
  variant?: "primary" | "previous" | "secondary";
  reverse?: boolean;
  className?: string;
  style?: React.CSSProperties;
  disable?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  children?: ReactNode;
  title?: string;
  tooltip?: string;
}

const Button: React.FC<ButtonProps> = (props) => (
  <button
    disabled={props.disable}
    id={props.id}
    type={props.type || "button"}
    onClick={props.onClick}
    className={`inline-flex gap-x-2 items-center whitespace-nowrap justify-center leading-150 !disabled:text-[#000000] ${
      props.reverse ? "flex-row-reverse" : ""
    } ${classes[props.size || "medium"]} ${classes[props.variant || "primary"]} ${
      props.className || ""
    }`}
    style={props.style}
    title={props.title}
    data-tip={props.tooltip}
  >
    {props.children}
  </button>
);

Button.defaultProps = {
  id: undefined,
  className: undefined,
  size: undefined,
  variant: undefined,
  reverse: undefined,
  style: undefined,
  disable: undefined,
  type: undefined,
  children: undefined,
  onClick: () => null,
  title: undefined,
  tooltip: undefined,
};

export default Button;
