import { toast } from "react-toastify";
import SuccessAlertIcon from "../Icons/SuccessAlertIcon";
import WarningAlertIcon from "../Icons/WarningAlertIcon";

export const successToast = (msg: string) =>
  toast.success(msg, {
    icon: (
      <span className="text-primary-main">
        <SuccessAlertIcon />
      </span>
    ),
  });

export const warningToast = (msg: string) => toast.warning(msg);

export const infoToast = (msg: string) => toast.info(msg);

export const errorToast = (msg: string) =>
  toast.error(msg, {
    icon: (
      <span className="text-[#F87171]">
        <WarningAlertIcon />
      </span>
    ),
  });
